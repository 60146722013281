<template>
  <div>
    <b-row>
      <b-col lg="12" class="profile-center">
        <tab-content id="pills-tabContent-2">
        <SetWidth>
          <KdiaDListFrom  color="#fff" :getMore="getMore" @setBusy="setBusy" @setItem="setItem" :title="'KDIA 입금 리스트'" :items="items" :isBusy="isBusy" :fieldsType="fieldsType" :total="total"/>
        </SetWidth>
        </tab-content>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import { core } from '../../config/pluginInit'
import { mapGetters } from 'vuex'
import wallet from '../../services/wallet/wallet'
import SetWidth from '../../components/setWidth.vue'
import KdiaDListFrom from './Components/KdiaDListFrom.vue'

export default {
  name: 'KdiaDList',
  components: {
    SetWidth,
    KdiaDListFrom
  },
  mounted () {
    core.index()
    this.getList()
  },
  methods: {
    getList () {
      wallet.getSendKdiaList(10, 0).then(res => {
        if (res.status !== 200) {
          return alert('서버 오류')
        }
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          this.setBusy()
          return
        }
        this.setBusy()
        if (res.data.list.length > 0) {
          this.fieldsType = 'list'
          this.items = res.data.list
          this.total = res.data.total
        } else {
          this.fieldsType = 'span'
          this.items = [{ pk: '', title: '입금 내용이 없습니다.' }]
          this.total = 1
        }
      })
    },
    getMore (page) {
      return wallet.getSendKdiaList(10, page)
    },
    setItem (item, total) {
      this.items = item
      this.total = total
    },
    setBusy () {
      this.isBusy = !this.isBusy
    }
  },
  data () {
    return {
      isBusy: true,
      items: [],
      total: 0,
      fieldsType: '',
      coinTitle: '(응원받은 코인 / 일별)',
      coinData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#f87979',
            data: []
          }
        ]
      },
      postedTitle: '(내가 쓴 글 / 일별)',
      postedData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#f87979',
            data: []
          }
        ]
      },
      modalShow: false
    }
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      walletState: 'WalletConnect/walletState'
    })
  }
}
</script>
